<template>
  <article class="collapse-item" :class="classes">
    <slot name="activator" :on-click="toogle" :active="isShown">
      <header
        class="header"
        :class="props.type"
        data-t="collapse-header"
        @click="toogle"
      >
        <slot name="name">
          <div class="name-wrapper">
            <StIcon v-if="icon" :name="icon" :size="20" class="sport-icon" />
            <h5 v-if="props.name" class="name">
              {{ props.name }}
            </h5>
            <div v-else class="name">
              <StSkeletonLoader height="12px" width="120px" />
            </div>
          </div>
        </slot>
        <div class="nav">
          <StIcon :name="collapseIconName" :size="16" />
        </div>
      </header>
    </slot>

    <StTransitionExpand :with-overflow="props.withOverflow">
      <div v-if="isShown" class="content" data-t="content">
        <slot />
      </div>
    </StTransitionExpand>
  </article>
</template>

<script lang="ts" setup>
import type { IconName } from '@st/ui/components/StIcon/types'
import { isNullish } from '@st/utils'

interface Props {
  name?: string
  icon?: IconName
  modelValue?: boolean
  withOverflow?: boolean
  type?: 'primary' | 'seconadry'
  size?: 'm' | 's'
  initialValue?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  withOverflow: false,
  type: 'seconadry',
  modelValue: undefined,
  size: 'm',
  initialValue: true,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const isLocallyOpened = ref(props.initialValue)

const isShown = computed({
  get: () => props.modelValue ?? isLocallyOpened.value ?? props.initialValue,
  set: (value) => {
    if (isNullish(props.modelValue)) {
      isLocallyOpened.value = value
    } else {
      emit('update:modelValue', value)
    }
  },
})

function toogle() {
  isShown.value = !isShown.value
}

const collapseIconName = computed(() =>
  isShown.value ? 'chevron-top' : 'chevron-down',
)

const classes = computed(() => [
  {
    active: isShown.value,
  },
  props.size,
])
</script>

<style scoped>
.name {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.48;
}

.header {
  cursor: pointer;

  position: relative;

  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  min-height: 44px;
  padding: var(--spacing-150);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-150);

  &:hover {
    .nav {
      opacity: 1;
    }
  }

  &.primary {
    background-color: var(--background-primary);
  }
}

.collapse-item {
  user-select: none;
  position: relative;
  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);

  &:not(:last-child) {
    margin-bottom: var(--spacing-100);
  }

  &.s {
    .name {
      font: var(--mobile-text-semibold);
    }

    .header {
      min-height: 40px;
      padding: var(--spacing-100) var(--spacing-100) var(--spacing-100)
        var(--spacing-150);
    }
  }
}

.name-wrapper {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  width: 100%;
}

.sport-icon {
  color: var(--text-tertiary);
}

.content {
  padding: 0 var(--spacing-100) var(--spacing-100) var(--spacing-100);
  background-color: var(--background-primary);
  border-radius: 0 0 var(--border-radius-150) var(--border-radius-150);
  box-shadow: 0 1px 1px rgb(0 19 56 / 12%);
}
</style>
